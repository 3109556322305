// This file is generated via scripts/build-static-config.js
  // Do not modify directly as changes will be overwritten in CI

  export const CONFIGURATION = {
    country: 'kr',
    brand: 'plk',
    env: 'prod',
    graphqlEnv: 'prod',
    graphqlGatewayEnv: '',
    sanityEnv: 'prod',
    platform: 'web',
    commitRef: '356f497d7b11259d869b186a3114e23f0ba29a4f',
    releaseTagRef: '2b0bac7',
    releaseTimestamp: '1733835727',
    sanityDataset: '',
    sanityProjectId: 'czqk28jt',
    appFlowBuildId: '',
    clickTrackingDomain: '',
    adyen: {
      clientKey: '',
      environment: '',
      urlPrefix: '',
      ideal: {
        idealBanks: [],
      }
    },
    apiKeys: {
      amplitude: '',
      branch: '',
      braze: 'fdf4f92c-92cf-49d7-b4d5-089c79f2e858',
      brazeCluster: 'sdk.iad-03.braze.com',
      bringg: '',
      googleMaps: 'AIzaSyC_6EtDTiEemJt95W-EY-rNoKjRDitO7G8',
      tomtom: '',
      launchDarkly: '6446875bbb88121262dd119a',
      launchDarklyMobileKey: 'mob-2b7735f5-2821-4d8c-a55a-accc29f0e4c5',
      mParticle: 'au1-96ca0691b884ea40ad64f4e23aded277',
      cookiebot: '',
      bloomreachTarget: '',
      bloomreachProjectToken: '',
      amplitudeCdpKey: '',
      amplitudeCdpRegion: '',
    },
    apple: {
      merchantId: '',
      migrationMerchantId: '',
      paymentsNetworks: [],
      prepaidMerchantId: '',
      migrationPrepaidMerchantId: '',
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    branch: {
      domains: []
    },
    checkoutDotCom: {
      publicKey: '',
    },
    facebook: {
      socialLogin: {
        appId: '',
        redirectURI: '',
      },
    },
    aws: {
      gqlApiUrl: 'https://apse1-prod-plk.rbictg.com/graphql',
      gqlGatewayApiUrl: 'https://apse1-prod-plk-gateway.rbictg.com/graphql',
      region: 'ap-southeast-1',
      userPoolClientId: '3okheo7ovb73igldsn6luemdbg',
      userPoolId: 'ap-southeast-1_yHpYkJlnf',
    },
    bringg: {
      region: '',
    },
    datadog: {
      logs: {
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
      rum: {
        applicationId: 'd2ad1579-fd2d-4500-a5d1-baa39289ebe4',
        clientToken: 'pub5560d3ea0973282cab306c73833e35d8',
        dataCenter: 'datadoghq.eu',
      },
    },
    firstData: {
      corporateStoreId: '',
    },
    forter: {
      siteId: 'idNotSet',
    },
    google: {
      paymentsEnvironment: '',
      paymentsGateway: '',
      marketPaymentsGatewayMerchantId: '',
      paymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPaymentsGateway: '',
      migrationPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      paymentsNetworks: [],
      prepaidPaymentsGateway: '',
      prepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      migrationPrepaidPaymentsGateway: '',
      migrationPrepaidPaymentsGatewayMerchantId: {
        ca: '',
        us: '',
        ch: '',
      },
      socialLogin: {
        clientId: '',
        redirectURI: '',
      },
    },
    googleAdManager: {
      campaignId: '',
    },
    googleTagManager: {
      containerId: '',
    },
    oneTrust: {
      cookieCompliance: {
        consentCategoryBranch: '',
        consentCategoryBraze: '',
        consentCategoryMParticle: '',
        globalConsentState: '',
        scriptUrl: '',
        scriptId: '',
      },
      deleteAccountUrl: '',
    },
    pypestream: {
      scriptUrl: '',
      appId: '',
    },
    restaurants: {
      displayEnv: 'prod',
      validMobileOrderingEnvs: ['alpha','beta','live'],
    },
    urls: {
      braze: '',
      firstData: 'https://api-int.payeezy.com',
      webBaseUrl: '',
    },
    tokenEx: {
      publicKey: '',
    },
    vrPayment: {
      braintreeTokenizationKey: '',
    },
    orbital: {
      baseUrl: '',
      subscriberId: '',
      pieFormat: '',
      mode: '',
    },
    timeFormat: '',
    dateFormat: 'MM/dd/yyyy',
    addressFormat: '',
    maxCartQuantity: '',
    appStoreDeeplink: '',
    iosDeepLink: '',
    androidDeepLink: '',
    cookieBanners: {
      cookieBanner:'customCookieBanner',
      scriptId: '',
      oneTrust: {
        cookieCompliance: {
          consentCategoryBranch: '',
          consentCategoryBraze: '',
          consentCategoryMParticle: '',
          consentCategoryDdRum: '',
          globalConsentState: '',
          scriptUrl: '',
          scriptId: '',
        },
      },
    }
  };